<template>
  <div class="d-flex flex-column content landing">
    <div class="flex-grow-1 d-flex flex-md-row flex-column">
      <div class="w-50 promo position-relative d-none d-md-block">
        <div class="middle">
          <Logo class="middle__logo" />
          <h2>{{ $t("general.auth.login.title") }}</h2>
        </div>
      </div>

      <div class="w-50 h-100 position-md-relative">
        <div class="mobile-auth">
          <div class="mobile-auth__hero d-md-none">
            <div class="mobile-auth__logo-wrapper">
              <Logo class="mobile-auth__logo" />
            </div>
          </div>

          <div class="mobile-auth__title">
            <span class="mobile-auth__font mobile-auth__font--title">
              {{ $t("general.auth.login.title") }}
            </span>
          </div>

          <div class="mobile-auth__content">
            <AppInfo
              class="mb-3"
              text=""
              type="error"
              v-if="errors.collect('_').length"
            >
              <ul class="pl-0">
                <li
                  class="list-unstyled"
                  v-for="errorItem in errorsList"
                  :key="errorItem"
                >
                  {{ errorItem }}
                </li>
              </ul>
            </AppInfo>

            <form novalidate class="form" @submit.prevent="submitForm">
              <CInput name="_" hidden />
              <CInput
                name="email"
                type="email"
                v-model="email"
                class="form__input"
                :label="$t('general.auth.login.form.email')"
                :rules="'required|email'"
              />

              <CInput
                type="password"
                name="password"
                v-model="password"
                :label="$t('general.auth.login.form.password')"
                :rules="'required'"
              />

              <CButton
                scope="itemscope"
                variant="primary"
                class="w-100 mb-3 text-uppercase"
                type="submit"
                :disabled="hasValidationError"
              >
                <span class="">
                  {{ $t("general.auth.login.button.login") }}
                </span>
              </CButton>

              <div
                class="d-flex small align-items-center justify-content-start"
              >
                <CLink to="/forgot" class="font__forgot">
                  {{ $t("general.forgot-password") }}
                </CLink>
              </div>
            </form>

            <div class="d-flex justify-content-between">
<!--              <SocialAuthButton class="w-50 mt-3" auth-variants="twitter">-->
<!--                {{ $t("general.auth.login.button.twitter") }}-->
<!--              </SocialAuthButton>-->

              <SocialAuthButton class="w-50 mt-3" auth-variants="google">
                {{ $t("general.auth.login.button.google") }}
              </SocialAuthButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="border-top p-3">
      <div align="center">
        <CLink type="a" to="/signup">{{ $t("general.signup") }}</CLink>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/models/User";

import CInput from "@/features/ui/СInput.vue";
import CButton from "@/features/ui/СButton.vue";
import CLink from "@/features/ui/CLink.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import SocialAuthButton from "@/features/containers/SocialAuthButton.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import Logo from "@/assets/svg/logo.svg";
import userLocation from "@/mixins/userLocation";

export default {
  components: {
    Logo,
    AppInfo,
    SocialAuthButton,
    // LayoutAuth,
    CInput,
    CLink,
    CButton,
  },
  mixins: [validationError, waitRequest, userLocation],
  data() {
    return {
      email: "",
      password: "",
    };
  },

  computed: {
    hasValidationError() {
      return !this.email || !this.password || (
        Boolean(this.errors?.collect("password").length) ||
        Boolean(this.errors?.collect("email").length)
      );
    },
    errorsList() {
      if(!this.errors.collect('_').length) return []

      return new Set(this.errors.collect('_'))
    },
  },

  methods: {
    submitForm() {
      this.login(User.CHANNEL_EMAIL);
    },

    login(type) {
      let fields = {
        channel_type: type,
        email: this.email,
        password: this.password,
      };

      return (
        this.waitRequest(() => {
          return this.$post(
            "/auth/login",
            fields,
            (data) => {
              this.$saveToken(data.token);
              this.$saveUser(data.user);
              this.$router.push("/");
              this.sendUserLocation();
            },
            (err) => {
              console.log(err);
            },
            (err) => this.checkErrors(err)
          );
        })
          // .catch(this.checkErrors)
          .catch((err) => {
            console.log(err);
          })
      );
    },
  },
};
</script>

<style scoped lang="scss">
.font {
  &__forgot {
    font-size: em(12);
  }
}

@import "~@/assets/scss/vendors/_variables.scss";
.promo {
  background: $app-blue;
  color: $white;
  .middle {
    width: 100%;
    max-width: 344px;
    text-align: left;

    &__logo {
      width: 50%;
      margin-bottom: em(16);
    }
    h2 {
      font-weight: normal;
    }
  }
}

.main {
  width: 344px;

  .logo {
    width: 80%;
    max-height: 4rem;
    margin: 0 auto;
  }
}

.mobile-auth {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  &__hero {
    background-image: url("/img/backgrounds/auth-login-background.webp");
    background-color: $black;
    height: em(160);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__logo-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    height: em(61);
    width: em(204);
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__title,
  &__content {
    padding-left: em(25);
    padding-right: em(25);
  }

  &__font {
    &--title {
      color: #000;
      font-family: $font-default;
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

@include media-breakpoint-down(sm) {
  .w-50 {
    width: 100% !important;
  }
  .main {
    width: 90%;
  }
}
</style>
