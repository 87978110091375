import User from "@/components/models/User";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { mapGetters } from "vuex";
import userLocation from "@/mixins/userLocation";

export default {
  mixins: [waitRequest, validationError, userLocation],
  computed: {
    ...mapGetters({ isAuth: "isAuth" }),
  },
  data() {
    return {};
  },
  methods: {
    socialLogin(type, token) {
      if (this.requestInProgress) return;

      let fields = {
        channel_type: type,
        token,
      };

      return this.waitRequest(() => {
        return this.$post(
          "/auth/login",
          fields,
          this.socialLoginSuccess,
          this.socialLoginFailure,
          this.checkErrors
        );
      }).this(this.checkErrors);
    },

    socialLoginSuccess(data) {
      this.$saveToken(data.token);
      this.$saveUser(data.user);
      this.$router.push("/");
      this.sendUserLocation()
    },

    socialLoginFailure(error) {
      console.log("error", error);
    },

    googleLogin() {
      if (this.requestInProgress || this.isAuth) return;

      if (!this.isExistGoogleScript()) {
        this.loadGoogleScript({ onloadCallback: this.googleLogin });
        return;
      }

      // eslint-disable-next-line
      const client = google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
        callback: this.onGoogleTokenReceivedCallback,
      });

      client.requestAccessToken();
    },

    onGoogleTokenReceivedCallback(data) {
      this.socialLogin(User.CHANNEL_GOOGLE, data.access_token);
    },

    onTwitterTokenReceivedCallback(data) {
      this.socialLogin(User.CHANNEL_TWITTER, data?.access_token);
    },

    loadGoogleScript({ onloadCallback = () => {} } = {}) {
      if (!this.isExistGoogleScript()) {
        let script = document.createElement("script");

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        script.onload = () => {
          onloadCallback();
        };

        document.head.appendChild(script);
      }
    },

    loadTwitterScript() {
      // console.log("twitter load");
    },

    async twitterLogin() {
      console.log("twitter login");
    },

    isExistGoogleScript() {
      let existingScript = Array.from(document.scripts).find(
        (script) => script.src === "https://accounts.google.com/gsi/client"
      );

      return Boolean(existingScript);
    },
  },
};
