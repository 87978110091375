<template>
  <button type="button" class="btn social-auth-button" @click="signIn">
    <!--    @click="authenticate(authVariants)"-->
    <span
      class="social-auth-button__icon-wrapper"
      :class="{
        [`social-auth-button__icon-wrapper--${authVariants}`]: authVariants,
      }"
    >
      <CIcon
        :name="authVariants"
        class="social-auth-button__icon"
        :class="{ [`social-auth-button__icon--${authVariants}`]: authVariants }"
      />
    </span>

    <span class="social-auth-button__font">
      <slot>
        {{ $t("general.auth.login.button.google") }}
      </slot>
    </span>
  </button>
</template>

<script>
// import User from "@/components/models/User";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import socialLogin from "@/config/socialLogin";

import CIcon from "@/features/ui/CIcon.vue";

const authVariants = {
  twitter: "twitter",
  google: "google",
};

export default {
  name: "SocialAuthButton",
  components: {
    CIcon,
  },
  mixins: [waitRequest, validationError, socialLogin],
  props: {
    authVariants: {
      type: String,
      default: authVariants.google,
      validator(value) {
        return [authVariants.google, authVariants.twitter].includes(value);
      },
    },
  },

  data() {
    return {
      client: null, // Google Auth instance
    };
  },
  mounted() {
    switch (this.authVariants) {
      case authVariants.google:
        return this.loadGoogleScript();
      case authVariants.twitter:
        return this.loadTwitterScript();
    }
  },
  methods: {
    signIn() {
      switch (this.authVariants) {
        case authVariants.google:
          return this.googleLogin();
        case authVariants.twitter:
          return this.twitterLogin();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.social-auth-button {
  max-width: em(167);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $app-blue;
  position: relative;
  border-radius: 100px;
  padding: em(4);

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: relative;
    top: 0;
    left: 0;
    width: em(23);
    height: em(23);

    border-radius: 50%;
    margin-right: em(8);

    &--google {
      background: $white;
    }

    &--twitter {
      margin-left: em(8);
    }
  }

  &__icon {
    position: relative;
    top: 0;
    left: 0;

    width: em(18);
    height: em(18);
  }

  &__font {
    flex: 1;
    color: $white;
    font-family: $font-default;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
}
</style>
